import React from "react";

const StyledParagraph = ({ text }) => {
  const formattedText = text.split(" ").map((word, index) => {
    if (word === word.toUpperCase()) {
      return (
        <span key={index} className="uppercaseBold">
          {word}{" "}
        </span>
      );
    }
    return word + " ";
  });

  return <p>{formattedText}</p>;
};




export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.webp" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About ARMANDO</h2>
              {/* <p>{props.data ? props.data.paragraph : "loading..."}</p> */}
              <h4>Augmented Real-time Monitoring and Alerts for Navigating Diverse Outdoors</h4>
              <h3>Vision</h3>
              <h5>
                We envision a world where  real-time location sharing, intelligent insights, and seamless group collaboration come together to create a more connected, efficient, and secure environment for all.
              </h5>
              <h3>Mission</h3>
              <h5>
                To be the leading platform that transforms the way people collaborate and navigate their daily lives, empowering them with innovative tools that prioritize safety, enhance productivity, and bring people together by fostering a sense of connection and community.
              </h5>

              {/* <h3>How?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
