import { Image } from "./image";
import React from "react";

export const MobileApps = (props) => {
  return (
    <div id="mobileapps" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Mobile Apps</h2>
          {/* <p>
            Coming soon!
          </p> */}
        </div>
        <div >
          <div >
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    // className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <div className="mobileapp-image-container">
                      <div style={{ marginRight: "10px" }}>
                        <h1>{d.message}</h1>
                        <br />
                        <br />
                      </div>

                      <div style={{ marginRight: "0px" }}>
                        <img src={d.image} alt={d.title} style={{"max-width": "100%", "height": "auto"}} />{" "}
                      </div>

                      <br />
                      <br />
                      <h2 style={{color: "rgb(59,27,14)"}}>{d.title}</h2>

                    </div>
                    {/* <Image */}
                      {/* title={d.title} */}
                      {/* largeImage={d.image} */}
                    {/* /> */}
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
